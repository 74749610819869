@import "~@/assets/scss/abstracts.scss";

.circular-progress {
	display: flex;
	position: relative;
	height: 138px;
	width: 138px;
	align-items: center;
	justify-content: center;
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.3s ease;

	&.is-loaded {
		visibility: visible;
		opacity: 1;
	}

	&-value {
		display: flex;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 10;
		width: 68px;
		height: 68px;
		padding: 10px;
		border-radius: 50%;
		background-color: rgba(255, 255, 255, 0.6);
		font-size: 24px;
		font-weight: 600;
		transform: translate(-50%, -50%);
		color: #424242;
		align-items: center;
		justify-content: center;
	}
}

.circle-chart__segment {
	transform-origin: center;
}
